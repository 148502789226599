import React from "react";
import { Box, Typography } from "@mui/material";
import Carousel from "react-multi-carousel";
import { useNavigate } from "react-router-dom";
import "react-multi-carousel/lib/styles.css";

function PromotionsCarousel() {
  const navigate = useNavigate();

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 1536 },
      items: 3,
    },
    desktop: {
      breakpoint: { max: 1536, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  const handleClick = () => {
    navigate("/Catuel");
  };

  return (
    <Box sx={{ width: "100%", height: "auto" }}>
      <Carousel
        responsive={responsive}
        infinite={true}
        autoPlay={true}
        autoPlaySpeed={3000}
        keyBoardControl={true}
        showDots={false}
        arrows={true}
        renderButtonGroupOutside={false}
        ssr={true}
        swipeable={true}
        draggable={true}
        shouldResetAutoplay={false}
        focusOnSelect={false}
      >
        <Box
          sx={{
            width: "100%",
            height: "400px",
            position: "relative",
            "&:hover": {
              cursor: "pointer",
              filter: "brightness(0.8)",
            },
          }}
          onClick={handleClick}
        >
          <img
            src="https://sanrafaelturismo.gov.ar/wp-content/uploads/2022/03/Copia-de-Vinedos-en-la-Cuesta-scaled.jpg"
            alt="Promoción 2"
            style={{ width: "100%", height: "100%", objectFit: "cover" }}
          />
        </Box>

        <Box
          sx={{
            width: "100%",
            height: "400px",
            position: "relative",
            "&:hover": {
              cursor: "pointer",
              filter: "brightness(0.8)",
            },
          }}
          onClick={handleClick}
        >
          <img
            src="https://d2r9epyceweg5n.cloudfront.net/stores/001/625/807/rte/imagen%20brindis%20personas.jpg"
            alt="Promoción 3"
            style={{ width: "100%", height: "100%", objectFit: "cover" }}
          />
        </Box>

        <Box
          sx={{
            width: "100%",
            height: "400px",
            position: "relative",
            "&:hover": {
              cursor: "pointer",
              filter: "brightness(0.8)",
            },
          }}
          onClick={handleClick}
        >
          <img
            src="https://latitur.com/uploads/media/cactus_product_tours_by_locals/12026.kalinawaventuras@gmail.com/18638/thumb_18638_cactus_product_tours_by_locals_large.jpeg.webp"
            alt="Promoción 4"
            style={{ width: "100%", height: "100%", objectFit: "cover" }}
          />
        </Box>
      </Carousel>
    </Box>
  );
}

export default PromotionsCarousel;
