import { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getReserves } from "../api/reserves";
import {
  Button,
  TextField,
  Typography,
  Grid,
  Card,
  CardContent,
  CardActions,
  Box,
  CardMedia,
  Toolbar,
  AppBar,
  Drawer,
  Fab,
} from "@mui/material";
import useSound from "use-sound"; // Hook para manejar sonido (instala use-sound)
import Badge from "@mui/material/Badge";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import agata1 from "../images/agata1.webp";
import AMATISTA2 from "../images/AMATISTA2.webp";
import AMATISTA from "../images/AMATISTA.webp";
import Jadeoke from "../images/jadeoke.webp";
import OBSIDIANA from "../images/OBSIDIANA.webp";
import OBSIDIANA2 from "../images/OBSIDIANA2.webp";
import RUBI from "../images/RUBI.webp";
import CITRINO from "../images/CITRINO.webp";
import CITRINO2 from "../images/citrino2.webp";
import DIAMANTE from "../images/DIAMANTE.webp";
import DIAMANTE2 from "../images/DIAMANTE2.webp";
import CUARZO from "../images/CUARZO.webp";

import "../styles/pulse.css"; // Asegúrate de que la ruta sea correcta
import Overlay from "../components/Overlay";

import FondoReserve from "../images/FondoReserve.png";
import CarouselM from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Carousel } from "react-responsive-carousel";
import PersonIcon from "@mui/icons-material/Person";
import AcUnitIcon from "@mui/icons-material/AcUnit";
import WavesIcon from "@mui/icons-material/Waves";
import BedroomParentIcon from "@mui/icons-material/BedroomParent";
import BedroomChildIcon from "@mui/icons-material/BedroomChild";
import WifiIcon from "@mui/icons-material/Wifi";
import LockIcon from "@mui/icons-material/Lock";
import GroupsIcon from "@mui/icons-material/Groups";
import FreeBreakfastIcon from "@mui/icons-material/FreeBreakfast";
import FemaleIcon from "@mui/icons-material/Female";
import aventuras from "../images/Carrusel1/aventuras.png";
import bodegas from "../images/Carrusel1/bodegas.png";
import cañon from "../images/Carrusel1/cañon.png";
import descuentos from "../images/Carrusel1/descuestos.png";
import disfruta from "../images/Carrusel1/disfruta.png";
import reyunos from "../images/Carrusel1/reyunos.png";
import turismo from "../images/Carrusel1/turismo.png";
import vallegrande from "../images/Carrusel1/vallegrande.png";
import clickSound from ".././sound/click.mp3"; // Importa tu archivo de sonido
import HomeIcon from "@mui/icons-material/Home";

const responsive = {
  xl: {
    breakpoint: { max: 3000, min: 1280 }, // xl tamaño máximo de pantalla
    items: 8, // Número de miniaturas visibles en pantallas grandes
  },
  lg: {
    breakpoint: { max: 1280, min: 960 }, // lg pantallas grandes
    items: 5, // Número de miniaturas visibles
  },
  md: {
    breakpoint: { max: 960, min: 600 }, // md pantallas medianas
    items: 4,
  },
  sm: {
    breakpoint: { max: 600, min: 480 }, // sm pantallas pequeñas
    items: 3,
  },
  xs: {
    breakpoint: { max: 480, min: 0 }, // xs pantallas muy pequeñas
    items: 2, // Mostrar 3 miniaturas en pantallas pequeñas
  },
};
const responsive2 = {
  xl: {
    breakpoint: { max: 3000, min: 1280 }, // xl tamaño máximo de pantalla
    items: 1, // Número de miniaturas visibles en pantallas grandes
  },
  lg: {
    breakpoint: { max: 1280, min: 960 }, // lg pantallas grandes
    items: 1, // Número de miniaturas visibles
  },
  md: {
    breakpoint: { max: 960, min: 600 }, // md pantallas medianas
    items: 1,
  },
  sm: {
    breakpoint: { max: 600, min: 480 }, // sm pantallas pequeñas
    items: 1,
  },
  xs: {
    breakpoint: { max: 480, min: 0 }, // xs pantallas muy pequeñas
    items: 1, // Mostrar 3 miniaturas en pantallas pequeñas
  },
};

const ReservePage = () => {
  const [mobileOpen, setMobileOpen] = useState(false);
  const [rooms, setRooms] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [occupants, setOccupants] = useState({});
  const navigate = useNavigate();
  const [badgeSize, setBadgeSize] = useState(1); // Estado para controlar el tamaño del badge
  const [play] = useSound(clickSound); // Hook para reproducir el sonido
  const formRef = useRef(null);
  const [isOverlayVisible, setOverlayVisible] = useState(false);
  const targetRef = useRef(null); // Ref para el elemento objetivo

  const imageUrl =
    "https://drive.google.com/drive/folders/1ryZm6qVLYS1fGMhvkad18Od4oHEaCESy";

  const totalNights =
    (new Date(endDate) - new Date(startDate)) / (1000 * 60 * 60 * 24);

  const selectedRooms = rooms.filter((room) => occupants[room.id] > 0);

  // Crear un nuevo array con la información de las habitaciones seleccionadas
  const roomsInfo = selectedRooms.map((room) => {
    const numOccupants = occupants[room.id]; // Número de ocupantes
    let totalPrice = 0;

    // Lógica para calcular el precio total según el tipo de habitación
    if (room.type === "Privada") {
      // Precios para habitaciones privadas
      if (numOccupants === 1 || numOccupants === 2) {
        totalPrice = 36000 * totalNights;
      } else if (numOccupants === 3) {
        totalPrice = 48000 * totalNights;
      } else if (numOccupants === 4) {
        totalPrice = 58000 * totalNights;
      } else if (numOccupants === 5) {
        totalPrice = 64000 * totalNights;
      }
    } else {
      // Para habitaciones no privadas
      totalPrice = room.pricePerNight * numOccupants * totalNights;
    }

    return {
      name: room.room,
      id: room.id,
      type: room.type,
      occupants: numOccupants, // Número de ocupantes
      totalNights, // Total de noches
      totalPrice, // Precio total de la habitación
    };
  });

  const handleFetchRooms = async () => {
    if (new Date(startDate) > new Date(endDate)) {
      alert("La fecha de inicio no puede ser posterior a la fecha de fin.");
      return;
    }

    try {
      const body = { startDate, endDate };
      const data = await getReserves(body);
      console.log(data.data);

      if (data.data) {
        setRooms(data.data);

        const initialOccupants = {};
        data.data.forEach((room) => {
          initialOccupants[room.id] = 0;
        });
        setOccupants(initialOccupants);
      } else {
        console.error(data.message);
        setRooms([]);
      }
    } catch (error) {
      console.error("Error fetching rooms:", error);
      setRooms([]);
    }
  };

  const handleReservation = (room) => {
    // Convertir fechas de disponibilidad a formato de fecha adecuado
    const nextAvailableDate = new Date(room.nextAvailableDate);
    const availableUntilDate = room.availableUntilDate
      ? new Date(room.availableUntilDate)
      : nextAvailableDate; // Si no hay 'availableUntilDate', usar 'nextAvailableDate' como fecha final

    // Ajustar las fechas de inicio y final
    const adjustedStartDate = new Date(nextAvailableDate);
    adjustedStartDate.setDate(adjustedStartDate.getDate() + 2); // Ajustar fecha de inicio sumando 4 días

    const adjustedEndDate = new Date(availableUntilDate);
    adjustedEndDate.setDate(adjustedEndDate.getDate() + 2); // Ajustar fecha final sumando 1 día

    // Formatear fecha en formato YYYY-MM-DD
    const formatDateForInput = (date) => {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");
      return `${year}-${month}-${day}`;
    };

    // Setear fechas formateadas
    setStartDate(formatDateForInput(adjustedStartDate));
    setEndDate(formatDateForInput(adjustedEndDate));

    // Desplazarse hacia el formulario
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  useEffect(() => {
    if (startDate && endDate) {
      handleFetchRooms(); // Llama a la función después de actualizar startDate y endDate
      play(); // Reproduce el sonido
    }
  }, [startDate, endDate]); // Ejecuta cuando startDate o endDate cambian

  const handleReserve = () => {
    // Crear nuevos valores de startDate y endDate sumando un día a cada uno
    const newStartDate = new Date(startDate);
    newStartDate.setDate(newStartDate.getDate() );

    const newEndDate = new Date(endDate);
    newEndDate.setDate(newEndDate.getDate());

    const totalPrice = roomsInfo.reduce(
      (acc, room) => acc + room.totalPrice,
      0
    );

    console.log(
      "Habitaciones seleccionadas:",
      JSON.stringify(roomsInfo, newStartDate, newEndDate, null, 2)
    );

    navigate("/pay", {
      state: {
        rooms: roomsInfo, // Enviar el nuevo array de habitaciones
        startDate: newStartDate,
        endDate: newEndDate,
        totalNights,
        totalPrice,
        occupants,
      },
    });
  };

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };
  const today = new Date().toISOString().split("T")[0];
  const incrementOccupants = (roomId) => {
    const room = rooms.find((r) => r.id === roomId);
    if (occupants[roomId] < room.available) {
      setOccupants({ ...occupants, [roomId]: occupants[roomId] + 1 });
      play(); // Reproduce el sonido
      setBadgeSize(1.2); // Aumenta el tamaño temporalmente

      // Restablece el tamaño después de un tiempo (500ms para la animación)
      setTimeout(() => {
        setBadgeSize(1);
      }, 500);
    }
  };

  const decrementOccupants = (roomId) => {
    if (occupants[roomId] > 0) {
      setOccupants({ ...occupants, [roomId]: occupants[roomId] - 1 });
      play(); // Reproduce el sonido
      setBadgeSize(1.2); // Aumenta el tamaño temporalmente

      // Restablece el tamaño después de un tiempo (500ms para la animación)
      setTimeout(() => {
        setBadgeSize(1);
      }, 500);
    }
  };

  const formatCurrency = (value) => {
    return value.toLocaleString("es-AR", {
      style: "currency",
      currency: "ARS",
      minimumFractionDigits: 0,
    });
  };

  const totalPrice = rooms.reduce((acc, room) => {
    const numOccupants = occupants[room.id]; // Número de ocupantes en la habitación actual

    if (numOccupants > 0) {
      let roomPrice = 0;

      // Verificamos si la habitación es privada
      if (room.type === "Privada") {
        // Lógica para precios de habitaciones privadas basado en ocupantes
        if (numOccupants === 1 || numOccupants === 2) {
          roomPrice = 36000;
        } else if (numOccupants === 3) {
          roomPrice = 48000;
        } else if (numOccupants === 4) {
          roomPrice = 58000;
        } else if (numOccupants === 5) {
          roomPrice = 64000;
        } else if (numOccupants === 6) {
          roomPrice = 64000;
        }

        // Aquí devolvemos directamente el precio de la habitación basado en el número de ocupantes
        acc += roomPrice * totalNights;
      } else {
        // Si no es privada, usamos el precio por noche estándar, multiplicado por el número de ocupantes
        roomPrice = room.pricePerNight;
        acc += numOccupants * roomPrice * totalNights;
      }
    }

    return acc;
  }, 0);

  console.log("Precio total:", totalPrice);

  // Función para contar ocupantes
  const countOccupants = () => {
    // Obtener los valores del objeto y contar cuántos son mayores que cero
    return Object.values(occupants).filter((occupant) => occupant > 0).length;
  };

  const handleDrawerToggle = () => {
    // Si no hay ocupantes, mostrar el overlay
    if (countOccupants() <= 0) {
      scrollToTarget();
      setOverlayVisible(true);
    } else {
      setMobileOpen(!mobileOpen); // Cambiar el estado del menú
    }
  };
  const scrollToTarget = () => {
    if (targetRef.current) {
      const rect = targetRef.current.getBoundingClientRect();
      const targetPosition = rect.top + window.scrollY - 400; // 20px hacia abajo

      const startPosition = window.scrollY;
      const distance = targetPosition - startPosition;
      const duration = 3500; // Duración en milisegundos
      const startTime = performance.now();

      const animateScroll = (currentTime) => {
        const elapsed = currentTime - startTime;
        const progress = Math.min(elapsed / duration, 1); // Limitar el progreso a 1
        const easeInOutQuad = (t) => {
          return t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t; // Función de easing
        };

        const scrollY = startPosition + distance * easeInOutQuad(progress);
        window.scrollTo(0, scrollY);

        if (progress < 1) {
          requestAnimationFrame(animateScroll);
        }
      };

      requestAnimationFrame(animateScroll);
    }
  };

  const handleCloseOverlay = () => {
    setOverlayVisible(false); // Cerrar el overlay
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ padding: 2 }}>
      <Grid item xs={12}>
        <Card
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            padding: 3,
            boxShadow: 3,
            borderRadius: 2,
            backgroundColor: "#2F251C",
          }}
        >
          <CardContent>
            <Typography color="#fff" variant="h5" align="center" gutterBottom>
              Resumen de Cotización
            </Typography>

            {/* Lista de habitaciones seleccionadas */}
            {rooms.map(
              (room) =>
                occupants[room.id] > 0 && (
                  <Box
                    key={room.id}
                    textAlign="center"
                    marginY={2}
                    padding={2}
                    border={1}
                    borderColor="grey.300"
                    borderRadius={2}
                    sx={{ backgroundColor: "#ffffff" }}
                  >
                    <Typography variant="body2" fontWeight="bold">
                      Habitación {room.type}: {room.room}
                    </Typography>
                    <Typography variant="body2">
                      Precio por noche: {formatCurrency(room.pricePerNight)}
                    </Typography>
                    <Typography variant="body2">
                      Para {occupants[room.id]} persona/s
                    </Typography>
                    <Typography variant="body2">
                      Durante {totalNights} noches
                    </Typography>
                  </Box>
                )
            )}

            <Typography color="#fff" variant="h5" align="center" marginY={1}>
              Precio total: {formatCurrency(totalPrice)}
            </Typography>
            <Box sx={{ textAlign: "center", color: "#EF2917" }}>
              <Typography sx={{ fontWeight: "bold" }}>
                *Revisa los datos de la reserva*
              </Typography>
            </Box>
            <Button
              onClick={handleReserve}
              variant="contained"
              color="secondary"
              fullWidth
              sx={{ marginTop: 2 }}
            >
              Ingresa tus Datos
            </Button>
          </CardContent>
        </Card>
      </Grid>
    </Box>
  );

  return (
    <Box
      sx={{
        backgroundColor: "#ebe4d2",
        backgroundImage: `url(${FondoReserve})`, // Correcta interpolación de la variable fondohome
        backgroundSize: "cover", // Asegura que la imagen de fondo cubra toda el área
        backgroundPosition: "top",
      }}
      className="container"
    >
      <Fab
        size="large" // FAB pequeño
        color="primary"
        sx={{
          position: "fixed",
          top: 16, // Distancia desde la parte superior
          right: 30, // Distancia desde la izquierda
          zIndex: 1000, // Asegura que el FAB esté por encima de otros elementos
        }}
        onClick={() => navigate("/")} // Redirige a la página de inicio
      >
        <HomeIcon />
      </Fab>
      <Box
        sx={{
          textAlign: "center",
        }}
      >
        <Typography
          color="white"
          sx={{
            display: {
              xs: "none", // Tamaño de fuente para pantallas pequeñas
              sm: "none", // Tamaño de fuente para pantallas medianas
              md: "block", // Tamaño de fuente para pantallas grandes
            },
            fontSize: {
              xs: "1.5rem", // Tamaño de fuente para pantallas pequeñas
              sm: "1.5rem", // Tamaño de fuente para pantallas medianas
              md: "2rem", // Tamaño de fuente para pantallas grandes
              lg: "2.5rem", // Tamaño de fuente para pantallas más grandes
            },
            paddingTop: {
              xs: "10vh", // Tamaño de fuente para pantallas pequeñas
              sm: "10vh", // Tamaño de fuente para pantallas medianas
              md: "10vh", // Tamaño de fuente para pantallas grandes
              lg: "10vh", // Tamaño de fuente para pantallas más grandes
            },

            paddingRight: {
              xs: "10vh", // Tamaño de fuente para pantallas pequeñas
              sm: "0vh", // Tamaño de fuente para pantallas medianas
              md: "0vh", // Tamaño de fuente para pantallas grandes
              lg: "0vh", // Tamaño de fuente para pantallas más grandes
            },

            paddingLeft: {
              xs: "10vh", // Tamaño de fuente para pantallas pequeñas
              sm: "0vh", // Tamaño de fuente para pantallas medianas
              md: "0vh", // Tamaño de fuente para pantallas grandes
              lg: "0vh", // Tamaño de fuente para pantallas más grandes
            },

            textShadow: "5px 5px 4px rgba(0, 0, 0, 0.9)", // Sombra paralela,
          }}
          gutterBottom
        >
          Vivi la Experiencia Mendoza, San Rafael con Privilegio
        </Typography>
      </Box>
      <Box
        sx={{
          paddingTop: {
            xs: "20vh", // Tamaño de fuente para pantallas pequeñas
            sm: "10vh", // Tamaño de fuente para pantallas medianas
            md: "10vh", // Tamaño de fuente para pantallas grandes
            lg: "10vh", // Tamaño de fuente para pantallas más grandes
          },

          paddingRight: {
            xs: "0vh", // Tamaño de fuente para pantallas pequeñas
            sm: "0vh", // Tamaño de fuente para pantallas medianas
            md: "0vh", // Tamaño de fuente para pantallas grandes
            lg: "0vh", // Tamaño de fuente para pantallas más grandes
          },

          paddingLeft: {
            xs: "0vh", // Tamaño de fuente para pantallas pequeñas
            sm: "0vh", // Tamaño de fuente para pantallas medianas
            md: "0vh", // Tamaño de fuente para pantallas grandes
            lg: "0vh", // Tamaño de fuente para pantallas más grandes
          },
          display: "flex",
          flexDirection: "column", // Siempre 'column' para que todo se apile verticalmente
          justifyContent: "center", // Centra el contenido horizontalmente
          alignItems: "center", // Centra el contenido verticalmente
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: {
              xs: "column", // para pantallas extra pequeñas (teléfonos)
              sm: "row", // para pantallas pequeñas en adelante (tabletas, etc.)
            },
            padding: { xs: 2, md: 4 }, // Padding ajustado para pantallas pequeñas y grandes
            borderRadius: 2, // Bordes redondeados
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", // Sombra para algo de profundidad
            width: { xs: "90%", sm: "70%", md: "50%" }, // Ancho dinámico basado en la pantalla
            gap: "3vh",
            backgroundColor: "#ebe4d2",
            alignItems: "center",
          }}
        >
          <form
            ref={formRef}
            onSubmit={(e) => {
              e.preventDefault();
              handleFetchRooms();
            }}
            style={{
              gap: "16px",
              width: "100%",
            }}
          >
            <Typography textAlign={"center"} paddingBottom={"3vh"}>
              Por favor, ingrese las fechas en las que busca hospedarse en San
              Rafael.
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "column", sm: "row" }, // Columnas en pantallas pequeñas, filas en pantallas medianas y grandes
                gap: "16px", // Añadir espacio entre los elementos
                width: "100%",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              <TextField
                label="Fecha de inicio"
                type="date"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
                required
                fullWidth
                placeholder="Selecciona una fecha"
                InputLabelProps={{ shrink: true }}
                inputProps={{
                  min: today, // Limitar la selección de fecha para que no sea anterior al día actual
                }}
              />

              <TextField
                label="Fecha de fin"
                type="date"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
                required
                fullWidth
                InputLabelProps={{ shrink: true }}
                inputProps={{
                  min: today, // Limitar la selección de fecha para que no sea anterior al día actual
                }}
              />
            </Box>
          </form>
        </Box>
      </Box>

      {rooms.length === 0 ? (
        <Box
          sx={{
            paddingTop: {
              xs: "15vh", // para pantallas extra pequeñas (teléfonos)
              sm: "15vh", // para pantallas extra pequeñas (teléfonos)
              mb: "15vh", // para pantallas extra pequeñas (teléfonos)
              lg: "29vh", // para pantallas extra pequeñas (teléfonos)
              xl: "25vh", // para pantallas pequeñas en adelante (tabletas, etc.)
            },
          }}
        >
          <CarouselM
            responsive={responsive} // Configuración responsive
            autoPlay
            infinite
            showThumbs={false}
            showStatus={false}
            containerClass="carousel-container"
            itemClass="carousel-item-padding-40-px"
            draggable={false} // Desactiva el arrastre en escritorio
            swipeable={true} // Habilita el swipe en móviles
            arrows={false}
          >
            <Box
              sx={{
                width: "219px",
                height: "100px",
              }}
            >
              <img
                src={aventuras}
                alt="Aventuras"
                style={{ width: "100%", height: "100%" }}
                onClick={() => navigate("/Catuel")}
              />
            </Box>

            <Box
              sx={{
                width: "219px",
                height: "100px",
              }}
            >
              <img
                src={bodegas}
                alt="Bodegas"
                style={{ width: "100%", height: "100%" }}
                onClick={() => navigate("/Cdvinos")}
              />
            </Box>

            <Box
              sx={{
                width: "219px",
                height: "100px",
              }}
            >
              <img
                src={cañon}
                alt="Cañon"
                style={{ width: "100%", height: "100%" }}
                onClick={() => navigate("/losreyunos")}
              />
            </Box>

            <Box
              sx={{
                width: "219px",
                height: "100px",
              }}
            >
              <img
                src={descuentos}
                alt="Descuentos"
                style={{ width: "100%", height: "100%" }}
                onClick={() => navigate("/Sabores")}
              />
            </Box>

            <Box
              sx={{
                width: "219px",
                height: "100px",
              }}
            >
              <img
                src={disfruta}
                alt="Disfruta"
                style={{ width: "100%", height: "100%" }}
                onClick={() => navigate("/Catuel")}
              />
            </Box>

            <Box
              sx={{
                width: "219px",
                height: "100px",
              }}
            >
              <img
                src={reyunos}
                alt="Reyunos"
                style={{ width: "100%", height: "100%" }}
                onClick={() => navigate("/Catuel")}
              />
            </Box>

            <Box
              sx={{
                width: "219px",
                height: "100px",
              }}
            >
              <img
                src={turismo}
                alt="Turismo"
                style={{ width: "100%", height: "100%" }}
                onClick={() => navigate("/Cdvinos")}
              />
            </Box>

            <Box
              sx={{
                width: "219px",
                height: "100px",
              }}
            >
              <img
                src={vallegrande}
                alt="Valle Grande"
                style={{ width: "100%", height: "100%" }}
                onClick={() => navigate("/Catuel")}
              />
            </Box>
          </CarouselM>

          <Typography
            color="white"
            variant="body1"
            sx={{ marginTop: "16px", textAlign: "center" }} // Ajustes de espaciado y alineación
          >
            Busca una fecha nueva
          </Typography>
        </Box>
      ) : (
        <Grid container spacing={3} sx={{ marginTop: "20px", paddingX: 2 }}>
          <Grid
            container
            spacing={3}
            sx={{ marginTop: "20px", paddingX: 2, paddingLeft: 5 }}
          >
            <Grid item xs={12} md={12}>
              <Grid id="rooms" container spacing={3}>
                {rooms
                  .sort((a, b) => b.available - a.available) // Luego ordena
                  .map((room) => (
                    <Grid item xs={12} sm={6} lg={3} key={room.id}>
                      <Card sx={{ boxShadow: 3, borderRadius: 2 }}>
                        <CardMedia>
                          {room.room === "jade" && (
                            <CarouselM
                              responsive={responsive2} // Configuración responsive
                              autoPlay
                              infinite={true}
                              showThumbs={true}
                              showStatus={true}
                              containerClass="carousel-container"
                              draggable={true} // Desactiva el arrastre en escritorio
                              swipeable={true} // Habilita el swipe en móviles
                              arrows={false}
                            >
                              <Box
                                sx={{
                                  width: "100%",
                                  height: "100%",
                                }}
                              >
                                <img
                                  src={Jadeoke}
                                  alt="Aventuras"
                                  style={{ width: "100%", height: "100%" }}
                                />
                              </Box>
                            </CarouselM>
                          )}
                          {room.room === "cuarzo" && (
                            <CarouselM
                              responsive={responsive2} // Configuración responsive
                              autoPlay
                              infinite={true}
                              showThumbs={true}
                              showStatus={true}
                              containerClass="carousel-container"
                              draggable={true} // Desactiva el arrastre en escritorio
                              swipeable={true} // Habilita el swipe en móviles
                              arrows={false}
                            >
                              <Box
                                sx={{
                                  width: "100%",
                                  height: "100%",
                                }}
                              >
                                <img
                                  src={CUARZO}
                                  alt="Aventuras"
                                  style={{ width: "100%", height: "100%" }}
                                />
                              </Box>
                            </CarouselM>
                          )}
                          {room.room === "diamante" && (
                            <CarouselM
                              responsive={responsive2} // Configuración responsive
                              autoPlay
                              infinite={true}
                              showThumbs={true}
                              showStatus={true}
                              containerClass="carousel-container"
                              draggable={true} // Desactiva el arrastre en escritorio
                              swipeable={true} // Habilita el swipe en móviles
                              arrows={false}
                            >
                              <Box
                                sx={{
                                  width: "100%",
                                  height: "100%",
                                }}
                              >
                                <img
                                  src={DIAMANTE}
                                  alt="Aventuras"
                                  style={{ width: "100%", height: "100%" }}
                                />
                              </Box>
                              <Box
                                sx={{
                                  width: "100%",
                                  height: "100%",
                                }}
                              >
                                <img
                                  src={DIAMANTE2}
                                  alt="Aventuras"
                                  style={{ width: "100%", height: "100%" }}
                                />
                              </Box>
                            </CarouselM>
                          )}
                          {room.room === "citrino" && (
                            <CarouselM
                              responsive={responsive2} // Configuración responsive
                              autoPlay
                              infinite={true}
                              showThumbs={true}
                              showStatus={true}
                              containerClass="carousel-container"
                              draggable={true} // Desactiva el arrastre en escritorio
                              swipeable={true} // Habilita el swipe en móviles
                              arrows={false}
                            >
                              <Box
                                sx={{
                                  width: "100%",
                                  height: "100%",
                                }}
                              >
                                <img
                                  src={CITRINO}
                                  alt="Aventuras"
                                  style={{ width: "100%", height: "100%" }}
                                />
                              </Box>
                              <Box
                                sx={{
                                  width: "100%",
                                  height: "100%",
                                }}
                              >
                                <img
                                  src={CITRINO2}
                                  alt="Aventuras"
                                  style={{ width: "100%", height: "100%" }}
                                />
                              </Box>
                            </CarouselM>
                          )}
                          {room.room === "rubi" && (
                            <CarouselM
                              responsive={responsive2} // Configuración responsive
                              autoPlay
                              infinite={true}
                              showThumbs={true}
                              showStatus={true}
                              containerClass="carousel-container"
                              draggable={true} // Desactiva el arrastre en escritorio
                              swipeable={true} // Habilita el swipe en móviles
                              arrows={false}
                            >
                              <Box
                                sx={{
                                  width: "100%",
                                  height: "100%",
                                }}
                              >
                                <img
                                  src={RUBI}
                                  alt="Aventuras"
                                  style={{ width: "100%", height: "100%" }}
                                />
                              </Box>
                            </CarouselM>
                          )}
                          {room.room === "obsidiana" && (
                            <CarouselM
                              responsive={responsive2} // Configuración responsive
                              autoPlay
                              infinite={true}
                              showThumbs={true}
                              showStatus={true}
                              containerClass="carousel-container"
                              draggable={true} // Desactiva el arrastre en escritorio
                              swipeable={true} // Habilita el swipe en móviles
                              arrows={false}
                            >
                              <Box
                                sx={{
                                  width: "100%",
                                  height: "100%",
                                }}
                              >
                                <img
                                  src={OBSIDIANA}
                                  alt="Aventuras"
                                  style={{ width: "100%", height: "100%" }}
                                />
                              </Box>
                              <Box
                                sx={{
                                  width: "100%",
                                  height: "100%",
                                }}
                              >
                                <img
                                  src={OBSIDIANA2}
                                  alt="Aventuras"
                                  style={{ width: "100%", height: "100%" }}
                                />
                              </Box>
                            </CarouselM>
                          )}
                          {room.room === "agata" && (
                            <CarouselM
                              responsive={responsive2} // Configuración responsive
                              autoPlay
                              infinite
                              showThumbs={true}
                              showStatus={true}
                              containerClass="carousel-container"
                              itemClass="carousel-item-padding-40-px"
                              draggable={true} // Desactiva el arrastre en escritorio
                              swipeable={true} // Habilita el swipe en móviles
                              arrows={true}
                            >
                              <Box
                                sx={{
                                  width: "100%",
                                  height: "100%",
                                }}
                              >
                                <img
                                  src={agata1}
                                  alt="Aventuras"
                                  style={{ width: "100%", height: "100%" }}
                                />
                              </Box>
                            </CarouselM>
                          )}
                          {room.room === "amatista" && (
                            <CarouselM
                              responsive={responsive2} // Configuración responsive
                              autoPlay
                              infinite
                              showThumbs={true}
                              showStatus={true}
                              containerClass="carousel-container"
                              itemClass="carousel-item-padding-40-px"
                              draggable={true} // Desactiva el arrastre en escritorio
                              swipeable={true} // Habilita el swipe en móviles
                              arrows={true}
                            >
                              <Box
                                sx={{
                                  width: "100%",
                                  height: "100%",
                                }}
                              >
                                <img
                                  src={AMATISTA2}
                                  alt="Aventuras"
                                  style={{ width: "100%", height: "100%" }}
                                />
                              </Box>
                              <Box
                                sx={{
                                  width: "100%",
                                  height: "100%",
                                }}
                              >
                                <img
                                  src={AMATISTA}
                                  alt="Aventuras"
                                  style={{ width: "100%", height: "100%" }}
                                />
                              </Box>
                            </CarouselM>
                          )}
                        </CardMedia>
                        <CardContent>
                          <Typography
                            ref={targetRef}
                            id="target-element"
                            sx={{ textAlign: "center" }}
                            variant="h5"
                          >
                            {capitalizeFirstLetter(room.room)}
                          </Typography>
                          <Typography
                            sx={{ textAlign: "center", mb: 3 }}
                            variant="body2"
                          >
                            Habitación {room.type}
                          </Typography>
                          {room.room === "agata" && (
                            <Typography
                              variant="body2"
                              display="flex"
                              alignItems="center"
                            >
                              <GroupsIcon sx={{ mr: 1 }} /> ({room.available}/10
                              lugares disponibles)
                            </Typography>
                          )}
                          {room.room === "cuarzo" && (
                            <Typography
                              variant="body2"
                              display="flex"
                              alignItems="center"
                            >
                              <GroupsIcon sx={{ mr: 1 }} /> ({room.available}/4
                              lugares disponibles)
                            </Typography>
                          )}
                          {room.room === "citrino" && (
                            <Typography
                              variant="body2"
                              display="flex"
                              alignItems="center"
                            >
                              <GroupsIcon sx={{ mr: 1 }} /> ({room.available}/5
                              lugares disponibles)
                            </Typography>
                          )}
                          {/* Amenities */}
                          {room.type === "Privada" && (
                            <Typography
                              variant="body2"
                              display="flex"
                              alignItems="center"
                            >
                              <BedroomParentIcon sx={{ mr: 1 }} /> 1 cama
                              matrimonial
                            </Typography>
                          )}
                          {room.room === "amatista" && (
                            <Typography
                              variant="body2"
                              display="flex"
                              alignItems="center"
                            >
                              <BedroomChildIcon sx={{ mr: 1 }} /> 3 camas
                              individuales
                            </Typography>
                          )}
                          {room.room === "diamante" && (
                            <Typography
                              variant="body2"
                              display="flex"
                              alignItems="center"
                            >
                              <BedroomChildIcon sx={{ mr: 1 }} /> 4 camas
                              individuales
                            </Typography>
                          )}
                          {room.room === "jade" && (
                            <Typography
                              variant="body2"
                              display="flex"
                              alignItems="center"
                            >
                              <BedroomChildIcon sx={{ mr: 1 }} /> 3 camas
                              individuales
                            </Typography>
                          )}
                          {room.room === "obsidiana" && (
                            <Typography
                              variant="body2"
                              display="flex"
                              alignItems="center"
                            >
                              <BedroomChildIcon sx={{ mr: 1 }} /> 3 camas
                              individuales
                            </Typography>
                          )}
                          {room.room === "rubi" && (
                            <Typography
                              variant="body2"
                              display="flex"
                              alignItems="center"
                            >
                              <BedroomChildIcon sx={{ mr: 1 }} /> 3 camas
                              individuales
                            </Typography>
                          )}
                          <Typography
                            variant="body2"
                            display="flex"
                            alignItems="center"
                          >
                            <WifiIcon sx={{ mr: 1 }} /> Conexión Wi-Fi
                          </Typography>
                          <Typography
                            variant="body2"
                            display="flex"
                            alignItems="center"
                          >
                            <FreeBreakfastIcon sx={{ mr: 1 }} /> Desayuno
                            incluido
                          </Typography>
                          <Typography
                            variant="body2"
                            display="flex"
                            alignItems="center"
                          >
                            <AcUnitIcon sx={{ mr: 1 }} /> Aire acondicionado
                          </Typography>
                          <Typography
                            variant="body2"
                            display="flex"
                            alignItems="center"
                          >
                            <WavesIcon sx={{ mr: 1 }} /> Calefacción central
                          </Typography>
                          {room.room === "agata" && (
                            <>
                              <Typography
                                variant="body2"
                                display="flex"
                                alignItems="center"
                              >
                                <LockIcon sx={{ mr: 1 }} /> Lockers personales
                              </Typography>
                            </>
                          )}
                          {room.room === "citrino" && (
                            <>
                              <Typography
                                variant="body2"
                                display="flex"
                                alignItems="center"
                              >
                                <FemaleIcon sx={{ mr: 1 }} /> Exclusivo Mujeres
                              </Typography>
                            </>
                          )}
                          <Box sx={{ p: 1 }}>
                            <Typography
                              sx={{ textAlign: "center" }}
                              variant="body2"
                            >
                              Costo por noche:
                              <Typography
                                sx={{ textAlign: "center" }}
                                variant="h5"
                              >
                                <Typography variant="h5">
                                  {room.type === "Privada" ? (
                                    <>
                                      {occupants[room.id] > 2 && (
                                        <span
                                          style={{
                                            color: "red",
                                            textDecoration: "line-through",
                                            fontSize: "0.8em",
                                            marginRight: "8px",
                                          }}
                                        >
                                          {formatCurrency(32000)}
                                        </span>
                                      )}
                                      {formatCurrency(
                                        occupants[room.id] >= 5
                                          ? 64000
                                          : occupants[room.id] > 3
                                          ? 58000
                                          : occupants[room.id] > 2
                                          ? 48000
                                          : room.pricePerNight
                                      )}
                                    </>
                                  ) : (
                                    formatCurrency(room.pricePerNight)
                                  )}
                                </Typography>

                                <Typography variant="body2">
                                  para {occupants[room.id]} Personas
                                </Typography>
                              </Typography>
                            </Typography>
                          </Box>
                        </CardContent>
                        <CardActions sx={{ justifyContent: "center" }}>
                          {room.available > 0 ? (
                            <>
                              <Button
                                variant="contained"
                                color="secondary"
                                onClick={() => decrementOccupants(room.id)}
                                disabled={occupants[room.id] === 0}
                              >
                                -
                              </Button>
                              <Typography>
                                <PersonIcon sx={{ mr: 1 }} />
                                {occupants[room.id]}
                              </Typography>

                              <Button
                                variant="contained"
                                color="secondary"
                                onClick={() => incrementOccupants(room.id)}
                                disabled={occupants[room.id] >= room.available}
                              >
                                +
                              </Button>
                            </>
                          ) : room.nextAvailableDate ? (
                            <Box
                              sx={{
                                backgroundColor: "red",
                                padding: "20px",
                                borderRadius: "8px",
                                textAlign: "center",
                                color: "white",
                                width: "100%", // para ocupar todo el ancho del Card
                              }}
                            >
                              <Typography variant="h6">
                                Ocupada, disponible a partir de{" "}
                                {(() => {
                                  const nextAvailableDate = new Date(
                                    room.nextAvailableDate
                                  );
                                  nextAvailableDate.setDate(
                                    nextAvailableDate.getDate() + 2
                                  ); // Añadir 2 días
                                  return nextAvailableDate.toLocaleDateString();
                                })()}{" "}
                                {room.availableUntilDate ? (
                                  <>
                                    hasta{" "}
                                    {(() => {
                                      const availableUntilDate = new Date(
                                        room.availableUntilDate
                                      );
                                      availableUntilDate.setDate(
                                        availableUntilDate.getDate() + 2
                                      ); // Añadir 2 días
                                      return availableUntilDate.toLocaleDateString();
                                    })()}
                                  </>
                                ) : (
                                  ""
                                )}
                              </Typography>

                              <Button
                                variant="contained"
                                color="primary"
                                sx={{ marginTop: "10px" }}
                                onClick={() => handleReservation(room)} // Pasar room como argumento
                              >
                                Reservar estas fechas
                              </Button>
                            </Box>
                          ) : (
                            <Typography variant="body2" color="text.secondary">
                              Ocupada indefinidamente
                            </Typography>
                          )}
                        </CardActions>
                      </Card>
                    </Grid>
                  ))}
              </Grid>
            </Grid>

            {/* Barra fija en la parte inferior */}
            <AppBar
              position="fixed"
              sx={{
                top: {
                  xs: 0, // Para pantallas extra pequeñas (arriba)
                  md: "auto", // Para pantallas medianas en adelante (no arriba)
                },
                bottom: {
                  xs: "auto", // Para pantallas extra pequeñas (arriba)
                  md: 0, // Para pantallas medianas en adelante (no arriba)
                },
                left: {
                  xs: "center", // Para pantallas extra pequeñas (no en el lado derecho)
                  md: 0, // Para pantallas medianas en adelante (lado derecho)
                },
                height: {
                  xs: "auto", // No ocupa toda la altura en pantallas extra pequeñas
                  md: "100px", // Ocupa toda la altura en pantallas medianas en adelante
                },
                width: {
                  xs: "100%", // Ocupa todo el ancho en pantallas pequeñas
                  md: "460px", // Ajusta el ancho de la barra en pantallas medianas en adelante
                },
                backgroundColor: {
                  xs: "#2F251C", // Ocupa todo el ancho en pantallas pequeñas
                },
                backdropFilter: {
                  xs: "none", // Sin desenfoque para pantallas medianas en adelante
                  mb: "blur(10px)", // Aplica desenfoque para pantallas pequeñas
                  lg: "blur(10px)", // Aplica desenfoque para pantallas pequeñas
                  xl: "blur(2px)", // Aplica desenfoque para pantallas pequeñas
                },
                borderRadius: {
                  xs: "none", // Sin desenfoque para pantallas medianas en adelante
                  mb: "10px", // Aplica desenfoque para pantallas pequeñas
                  lg: "10px", // Aplica desenfoque para pantallas pequeñas
                  xl: "10px", // Aplica desenfoque para pantallas pequeñas
                },
                borderColor: {
                  xs: "none", // Sin desenfoque para pantallas medianas en adelante
                  mb: "black", // Aplica desenfoque para pantallas pequeñas
                },
                border: "2px solid #000",

                justifyContent: "center",
              }}
            >
              <Toolbar sx={{ textAlign: "center" }}>
                <Box
                  sx={{
                    padding: { xs: "3vh" },
                  }}
                >
                  <Badge
                    badgeContent={Object.values(occupants).reduce(
                      (total, count) => total + count,
                      0
                    )}
                    color="error"
                    sx={{
                      transform: `scale(${badgeSize})`, // Escala para agrandar/reducir el Badge
                      transition: "transform 0.3s ease-in-out", // Animación suave
                      cursor: "pointer", // Cambia el cursor al pasar sobre el Badge
                    }}
                  >
                    <Typography
                      style={{ color: "white" }}
                      sx={{
                        fontSize: { xs: "1.4rem" },
                      }}
                    >
                      Nuevos Huéspedes
                      <PersonIcon sx={{ mr: 1 }} />
                    </Typography>
                  </Badge>
                  <Button
                    className="pulse-button" // Aplica la clase aquí
                    variant="contained"
                    color="secondary"
                    onClick={handleDrawerToggle}
                  >
                    Consultar Precios
                  </Button>
                </Box>
              </Toolbar>
            </AppBar>
          </Grid>

          <Drawer
            anchor="bottom" // El Drawer se anclará desde abajo
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            sx={{
              display: { xs: "block" }, // Mostrado en pantallas pequeñas
              "& .MuiDrawer-paper": {
                boxSizing: "border-box", // Para evitar que el contenido del drawer se desborde
                height: {
                  xs: "100%", // Altura completa en pantallas pequeñas
                  md: "auto", // Ajusta la altura en pantallas medianas en adelante
                },
                width: {
                  xs: "100%", // 100% de ancho en pantallas pequeñas
                  md: "100%", // 100% de ancho en pantallas medianas
                  lg: "460px", // Ancho fijo de 460px en pantallas grandes
                  xl: "460px", // Ancho fijo de 460px en pantallas extra grandes
                },
              },
            }}
          >
            {drawer}
          </Drawer>
        </Grid>
      )}
      <Overlay
        isVisible={isOverlayVisible}
        setIsVisible={setOverlayVisible}
        targetRef={targetRef}
        onClose={handleCloseOverlay}
      />
    </Box>
  );
};

export default ReservePage;
